import React, { useEffect } from "react";
import { TableContainer, Table } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  useGetCustomerParts,
  useGetOrderEditData,
  useGetWorkOrderByOrderId,
} from "../../hooks";
import { HubPagination } from "../../components";

import OrderTableSkeleton from "./OrderTableSkeleton";
import OrderLineItemsHeader from "./OrderLineItemsHeader";
import OrderLineItemsBody from "./OrderLineItemsBody";

export const OrderLineItemsTable = () => {
  const { id } = useParams();
  const { data, isLoading: workLoading } = useGetWorkOrderByOrderId(id);
  const { data: order } = useGetOrderEditData(id);
  const {
    data: parts,
    refetch,
    isLoading: partLoading,
  } = useGetCustomerParts(order?.customer?.id);

  const isLoading = workLoading || partLoading;

  useEffect(() => {
    refetch();
  }, [order?.customer?.id]);

  const meta = data?.meta;

  const payload = {
    items: data?.rows,
    parts,
    startWorkOrder: order?.startWorkOrder,
  };

  return isLoading ? (
    <OrderTableSkeleton />
  ) : (
    <>
      <TableContainer sx={{ backgroundColor: "hubColors.white" }}>
        <Table id="CustomerOrdersTable">
          <OrderLineItemsHeader />
          <OrderLineItemsBody {...payload} />
        </Table>
      </TableContainer>
      <HubPagination {...meta} />
    </>
  );
};

export default OrderLineItemsTable;
