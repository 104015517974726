import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs-lite";

import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";
import { CUSTOMERS } from "./useCustomerData";

export const ORDERS = "/order";

const postOrderData = (data) => api.post(ORDERS, data);
export const usePostOrderData = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postOrderData, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMERS);
      queryClient.invalidateQueries(ORDERS);
      addSnackbarSuccess({ message: "Successfully Created Purchase Order" });
    },
  });
};

const putOrderData = (data) => {
  const id = data.id;
  delete data.id;
  return api.put(`${ORDERS}/${id}`, data);
};
export const usePutOrderData = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putOrderData, {
    onSuccess: () => {
      queryClient.invalidateQueries(ORDERS);
      addSnackbarSuccess({ message: "Successfully Updated Purchase Order" });
    },
  });
};

const getAllOrderData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${ORDERS}?${queryString}`);
};

export const useGetAllOrderData = ({ per, start, end, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const queryLocation = query.get("location");
  const location =
    queryLocation === "All" ? "" : queryLocation || defaultLocation;
  const status = query.get("status");

  const orderBy = query.get("orderBy") || "createdAt";
  const sortOrder = query.get("sortOrder") || "desc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const period = query.get("period") || per;
  const startDate = query.get("startDate") || start;
  const endDate = query.get("endDate") || end;

  const queryProps = {
    ...(location ? { location } : {}),
    ...(status != "All" && status ? { status } : {}),
    limit,
    offset,
    orderBy,
    sortOrder,
    period,
    startDate,
    endDate,
  };

  return useQuery([ORDERS, { ...queryProps }], getAllOrderData, {
    enabled: !!defaultLocation,
  });
};

const deleteOrderById = (id) => api.delete(`${ORDERS}/${id}`);
export const useDeleteOrderById = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(deleteOrderById, {
    onSuccess: () => {
      queryClient.invalidateQueries(ORDERS);
      queryClient.invalidateQueries(CUSTOMERS);
      addSnackbarSuccess({ message: "Successfully Deleted Order" });
    },
  });
};

export const getOrderEditData = (id) => api.get(`${ORDERS}/edit/${id}`);
export const useGetOrderEditData = (id) => {
  return useQuery([ORDERS], () => getOrderEditData(id), {
    enabled: !!id,
  });
};
