export const tabs = [
  {
    id: 1,
    label: "Wax",
  },
  {
    id: 2,
    label: "Dip",
  },
  {
    id: 4,
    label: "Foundry",
  },
  {
    id: 5,
    label: "Post Foundry",
  },
  {
    id: 6,
    label: "Outside Processing",
  },
  {
    id: 7,
    label: "Inspection",
  },
];
