import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDisplayStack = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.hubColors.white,
  boxSizing: "border-box",
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root:nth-child(3)": {
      backgroundColor: theme.palette.hubColors.mainFocusLightest,
    },
  },
  "& .MuiTableRow-root:hover": {
    "& .MuiTableCell-root:nth-child(3)": {
      backgroundColor: theme.palette.hubColors.mainFocusLighter2,
    },
  },
  // "& .MuiTableRow-root:first-of-type": {
  //   "& .MuiTableCell-root:nth-child(3)": {
  //     backgroundColor: theme.palette.hubColors.greyLighter,
  //   },
  // },
  // "& .MuiTableRow-root:first-of-type:hover": {
  //   "& .MuiTableCell-root:nth-child(3)": {
  //     backgroundColor: theme.palette.hubColors.greyLighter,
  //   },
  // },
}));

export const StyledStackGrid = styled(Stack)({
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  gap: "15px",
  height: "100%",
  width: "100%",
  overflow: "hidden",
});

export const StyledGridRow = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "15px",
  height: "100%",
  width: "100%",
  overflow: "hidden",
});
