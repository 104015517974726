import React, { useState } from "react";
import { TableBody, TableRow, TableCell, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getDateData } from "../../helpers/dateHelper";
import { getDollarValue } from "../../helpers/currency";
import HubTableSubRow from "./HubTableSubRow";

const HubTableBody = ({
  items = [],
  keys = [],
  condition = () => {},
  RenderElement,
  onRowClick,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleNav = (e, route, id) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/${route}/${id}`);
  };

  const handleClick = (e, item, onClick = () => {}) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(item);
  };

  const getCellProps = (key, item) => ({
    ...(key?.sx ? { sx: key.sx } : {}),
    ...(key?.variant ? { variant: key.variant } : {}),
    ...(key?.onClick
      ? { onClick: (e) => handleClick(e, item, key.onClick) }
      : {}),
    ...(key?.navigate
      ? { onClick: (e) => handleNav(e, key.route, item[key.id]) }
      : {}),
    ...(key?.isCheckbox ? { padding: "checkbox" } : {}),
  });

  const conditionalFormatting = (item) => condition(item);

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const processValue = (item, key) => {
    const value = getNestedValue(item, key.value) || key?.defaultValue;

    if (key.isCheckbox)
      return <Checkbox checked={value} sx={{ padding: 0 }} disabled />;
    if (!value && typeof value !== "number") return value;
    if (key.isCurreny) return getDollarValue(value);
    if (key.isDate)
      return getDateData({
        startDate: value,
        includeYear: true,
      });
    if (key.isDateTime) {
      return getDateData({
        startDate: value,
        includeYear: true,
        includeTime: true,
      });
    }

    return value;
  };

  const handleRowClick = (e, item, idx) => {
    onRowClick && onRowClick(e, item);
    if (RenderElement) {
      if (open === idx) setOpen(null);
      else setOpen(idx);
    }
  };

  return (
    <TableBody>
      {items.map((item, index) => {
        return (
          <>
            <TableRow
              key={index}
              {...conditionalFormatting(item)}
              onClick={(e) => handleRowClick(e, item, index)}
            >
              {keys.map((key) => (
                <TableCell {...getCellProps(key, item)}>
                  {processValue(item, key)}
                </TableCell>
              ))}
            </TableRow>
            {RenderElement && (
              <HubTableSubRow
                isOpen={open === index && open !== null}
                item={item}
                length={keys?.length}
                RenderElement={RenderElement}
              />
            )}
          </>
        );
      })}
    </TableBody>
  );
};

export default HubTableBody;
