import React, { useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { getDollarValue } from "../../helpers/currency";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { onUpdateDateHelper } from "./helper";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";
import OrderLineItemChip from "./OrderLineItemChip";
import { getDateData } from "../../helpers/dateHelper";

const OrderLineItemRow = ({
  idx,
  item = {},
  parts = [],
  disabled = false,
  complete = false,
  deletedValues = [],
  updatedItems = []
}) => {
  const { setValue, getValues, register } = useFormContext();

  const [deleted, setDeleted] = useState(deletedValues.includes(item.id));

  const partItems = parts?.map((p) => ({ id: p.id, label: p.name }));

  const prefix = `items[${idx}].`;

  const existing = updatedItems.find((u) => u.id === item.id) || {};
  const rowItem = { ...item, ...existing };

  useEffect(() => {
    if (deleted) {
      const addedDeleted = [...new Set([...deletedValues, item.id])];
      setValue("deleted", addedDeleted);
    }
  }, [deleted]);

  useEffect(() => {
    register(prefix + "trees", { required: true });
    register(prefix + "patReq", { required: true });
    register(prefix + "margin", { required: true });
  }, [register, prefix]);

  useEffect(() => {
    const partId = rowItem.partId;
    const part = parts?.find((p) => p?.id === partId);

    const trees = Math.ceil(rowItem.quantity / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    setValue(prefix + "trees", trees, { shouldDirty: false });
    setValue(prefix + "patReq", patReq, { shouldDirty: false });
    setValue(prefix + "margin", rowItem.margin, { shouldDirty: false });
  }, [rowItem.partId, rowItem.quantity, rowItem.margin]);

  const onFieldUpdate = () => {
    const preItem = getValues(`items[${idx}]`);
    let updatedItem = { ...item, ...preItem };

    const updatedIndex = updatedItems.findIndex((u) => u.id === item.id);
    updatedIndex > -1
      ? (updatedItems[updatedIndex] = updatedItem)
      : updatedItems.push(updatedItem);

    setValue("updated", updatedItems);
  };

  const onUpdatePart = (partId) => {
    const part = parts?.find((p) => p?.id === partId);
    setValue(prefix + "price", part?.lastSentPrice);
    onFieldUpdate();
    // _fetchPartJobData(part?.id, idx);
  };

  const onUpdatePrice = (price) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);

    let margin = 0.33;
    if (part?.lastSentPrice) {
      margin = (price * part.margin) / part.lastSentPrice;
      margin = Math.round(margin * 100) / 100;
    }

    setValue(prefix + "margin", margin);
    onFieldUpdate();
  };

  const onUpdateQuantity = (quantity) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);

    const trees = Math.ceil(quantity / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    setValue(prefix + "quantity", quantity);
    setValue(prefix + "trees", trees);
    setValue(prefix + "patReq", patReq);
    onFieldUpdate();
  };

  const onUpdateDate = (value, isStart = false) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);
    const { startDate, dueDate } = onUpdateDateHelper(
      value,
      isStart,
      part?.totalDays
    );

    setValue(prefix + "startDate", startDate);
    setValue(prefix + "endDate", dueDate);
    onFieldUpdate();
  };

  const WorkOrder =
    rowItem?.woNumber || `W${(rowItem?.workOrder).toString().padStart(5, "0")}`;

  if (deleted) return <></>;
  return (
    <>
      <StyledTableRow key={rowItem.id} bgDisabled>
        <TableCell>
          {rowItem?.state && <OrderLineItemChip state={rowItem?.state} />}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          {complete ? (
            <Typography variant="caption11">{rowItem?.lineItem}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "lineItem"}
              placeholder="Line"
              defaultValue={rowItem?.lineItem || "1"}
              onBlur={() => onFieldUpdate()}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 250, minWidth: 250 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.partName}</Typography>
          ) : (
            <HubHookFormSelect
              name={prefix + "partId"}
              onChange={(e) => onUpdatePart(e?.target?.value)}
              defaultValue={rowItem?.partId}
              options={partItems}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{WorkOrder}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "workOrder"}
              placeholder="W00000"
              defaultValue={WorkOrder}
              onBlur={() => onFieldUpdate()}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} disabled={complete}>
          {complete ? (
            <Typography variant="caption11">{rowItem?.lotNumber}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "lotNumber"}
              placeholder="UT240000"
              defaultValue={rowItem?.lotNumber}
              onBlur={() => onFieldUpdate()}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.quantity}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "quantity"}
              placeholder="1000"
              defaultValue={rowItem?.quantity}
              onBlur={(e, value) => onUpdateQuantity(value)}
              disableHelperText
              isNumber
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 125, minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDollarValue(rowItem?.price)}
            </Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "price"}
              placeholder="10.00"
              defaultValue={rowItem?.price}
              onBlur={(e, value) => onUpdatePrice(value)}
              disabled={disabled}
              disableHelperText
              isCurrency
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDateData({
                startDate: rowItem?.startDate,
                includeYear: true,
              })}
            </Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={prefix + "startDate"}
              placeholder="MM/DD/YYYY"
              defaultValue={rowItem?.startDate}
              onChange={(value) => onUpdateDate(value, true)}
              disabled={!rowItem?.partId}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDateData({ startDate: rowItem?.endDate, includeYear: true })}
            </Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={prefix + "endDate"}
              placeholder="MM/DD/YYYY"
              defaultValue={rowItem?.endDate}
              onChange={(value) => onUpdateDate(value)}
              disabled={!rowItem?.partId}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          <HubHookFormInput
            name={prefix + "id"}
            defaultValue={rowItem?.id}
            disableHelperText
            hidden
          />
          <LineItemContextMenu
            id={idx}
            disabled={disabled}
            removeItem={() => setDeleted(true)}
          />
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default React.memo(OrderLineItemRow);
