import React, { useEffect, useState } from "react";
import { TableBody } from "@mui/material";

import { JOB_STATUSES } from "../../constants";
import OrderLineItemRow from "./OrderLineItemRow";
import OrderLineItemNewRow from "./OrderLineItemNewRow";
import { addItemHelper } from "./helper";
import { useFormContext } from "react-hook-form";

const OrderLineItemsBody = ({ items = [], parts = [], startWorkOrder = 0 }) => {
  const [newRows, setNewRows] = useState([]);

  const { setValue, register, watch } = useFormContext();

  useEffect(() => {
    register("deleted");
    setValue("deteled", []);
  }, [register]);

  useEffect(() => {
    if (!newRows.length) {
      const rows = addItemHelper([], startWorkOrder);
      setNewRows(rows);
    }
  }, [startWorkOrder, newRows]);

  const addNewRow = () => {
    const rows = addItemHelper(newRows, startWorkOrder);
    setNewRows(rows);
  };

  const removeNewRow = (id) => {
    // const updatedItems = newRows.filter(({ idx }) => id !== idx);
    // setNewRows(updatedItems);
    // console.log({ formRows });
  };

  const newPayload = { addNewRow, removeNewRow, parts };

  const deletedValues = watch("deleted") || [];
  const updatedItems = watch("updated") || [];
  const itemPayload = { parts, deletedValues, updatedItems };

  return (
    <TableBody>
      {newRows.map((item) => {
        const payload = { item, idx: item.idx, ...newPayload };
        return <OrderLineItemNewRow key={JSON.stringify(item)} {...payload} />;
      })}
      {items.map((item, idx) => {
        const disabled = item?.state != JOB_STATUSES.NOT_STARTED && item?.state;
        const complete = item?.state === JOB_STATUSES.COMPLETE;
        const payload = { item, idx, disabled, complete, ...itemPayload };
        return <OrderLineItemRow key={JSON.stringify(item)} {...payload} />;
      })}
    </TableBody>
  );
};

export default OrderLineItemsBody;
