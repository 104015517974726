import React, { useEffect } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { tabs } from "./helper";
import { getUserRole } from "../../redux";
import { ROLES } from "../../constants";
import { SideNavBar } from "../../components";

export const DisplaysMenu = ({ nav, setNav = () => {}, role }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const property = query.get("view");

  const setValue = (id) => {
    query.set("view", id);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  useEffect(() => {
    setValue(property || tabs[0].id);
  }, [property, tabs]);

  const showSwitch = role !== ROLES.Generic;
  const handleSwitch = () => setNav(!nav);

  const EndComponent = showSwitch && (
    <Stack direction="row" justifyContent="space-between" p="0 15px">
      <Typography variant="caption">Worker View:</Typography>
      <Switch checked={!nav} onChange={handleSwitch} />
    </Stack>
  );

  const props = { tabs, EndComponent, value: Number(property), setValue };
  return <SideNavBar {...props} />;
};

const mapStateToProps = (state) => ({
  role: getUserRole(state),
});
export default connect(mapStateToProps)(DisplaysMenu);
