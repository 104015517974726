import React from "react";
import { TableRow, TableCell, Collapse } from "@mui/material";

const HubTableSubRow = ({
  isOpen = false,
  isLoading,
  item = {},
  length = 5,
  RenderElement,
}) => {
  const sx = isOpen
    ? { ":hover": { backgroundColor: "transparent !important" } }
    : { border: "none" };

  return (
    <TableRow sx={sx}>
      <TableCell colSpan={length} sx={{ padding: "0px !important" }}>
        <Collapse in={isOpen && !isLoading}>
          {RenderElement && <RenderElement item={item} />}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default HubTableSubRow;
