import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const condition = (item, isLast) => {
  const label = `& .MuiTableCell-root:${
    isLast ? "last-of-type" : "first-of-type"
  }`;
  if (dayjs(item.startDate).utc().add(1, "day") < dayjs().utc()) {
    return {
      sx: {
        "&.MuiTableRow-root": {
          "& .MuiTableCell-root:first-of-type": {
            backgroundColor: "rgba(239, 55, 55, 0.9)",
            color: "hubColors.fontWhite",
          },
        },
        "&.MuiTableRow-root:hover": {
          [label]: {
            backgroundColor: "rgba(239, 55, 55, 1) !important",
            color: "hubColors.fontWhite",
          },
        },
      },
    };
  }
};
