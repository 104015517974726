import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDisplayStack = styled(Stack)({
  gap: "25px",
  width: "100%",
  height: "calc(100vh - 51px)",
  overflow: "auto",
});

export const StyledContentStack = styled(Stack)({
  flexDirection: "row",
  width: "100%",
  height: "calc(100% - 51px);",
  overflow: "hidden",
});
