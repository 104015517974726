import React, { useState } from "react";
import { connect } from "react-redux";

import { getUserRole } from "../../redux";
import { WaxTable } from "../../tables";
import DisplaysMenu from "./DisplaysMenu";

import { StyledContentStack, StyledDisplayStack } from "./styles";
import { ROLES } from "../../constants";

const mapStateToProps = (state) => ({
  role: getUserRole(state),
});

export const DisplaysPage = connect(mapStateToProps)(({ role }) => {
  const defaultNav = ROLES.Generic !== role;
  const [nav, setNav] = useState(defaultNav);
  const props = { nav, setNav };

  return (
    <StyledContentStack>
      <DisplaysMenu {...props} />
      <StyledDisplayStack>
        <WaxTable {...props} />
      </StyledDisplayStack>
    </StyledContentStack>
  );
});

export default DisplaysPage;
