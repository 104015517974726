import { formatDateMmDdYyyy } from "../../helpers/dateHelper";

export const itemTemplate = {
  lineItem: 1,
  partId: 0,
  margin: 0.33,
  hours: 0,
  quantity: 100,
  price: 0.0,
  woNumber: "",
  lotNumber: "",
};

export const addItemHelper = (items = [], startWorkOrder) => {
  const addedItem = {
    ...itemTemplate,
    idx: items?.length,
  };

  const newItems = [addedItem, ...items];
  return newItems?.map((item) => ({
    ...item,
    workOrder: startWorkOrder + item?.idx,
  }));
};

export const onUpdateDateHelper = (value, isStart = false, daysToAdd = 0) => {
  let currentDate = new Date(value);

  const startAdd = isStart ? 1 : -1;
  let inDays = daysToAdd * startAdd;
  let it = Math.abs(inDays);

  while (it >= 0) {
    let adjustWeekend = false;

    let date = new Date(currentDate.setDate(currentDate.getDate() + startAdd));
    while (date.getDay() % 6 === 0) {
      currentDate.setDate(currentDate.getDate() + startAdd);
      date = new Date(currentDate);
      adjustWeekend = true;
    }

    currentDate = new Date(date);
    it--;
  }

  const second = formatDateMmDdYyyy(currentDate);

  const data = {
    startDate: isStart ? value : second,
    dueDate: isStart ? second : value,
  };

  return data;
};
