import React from "react";
import { Chip } from "@mui/material";

import { JOB_STATUSES } from "../../constants";

const flow = {
  [JOB_STATUSES.NOT_STARTED]: "grey",
  [JOB_STATUSES.IN_PROGRESS]: "green",
  [JOB_STATUSES.COMPLETE]: "main",
};
const OrderLineItemChip = ({ state = "" }) => {
  const final = state || JOB_STATUSES.NOT_STARTED;
  return (
    <Chip
      label={final}
      variant="colorTag"
      flow={flow[final]}
      sx={{
        margin: "6px",
        "& .MuiChip-label": {
          fontSize: 10,
        },
      }}
    />
  );
};

export default OrderLineItemChip;
