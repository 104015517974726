import qs from "qs-lite";
import { useQuery } from "react-query";
import api from "../../utils/api";

const DISPLAY = "/display";

const getDisplayDashboard = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${DISPLAY}?${queryString}`);
};

export const useGetDisplayDashboard = ({ labor = false }) => {
  const query = new URLSearchParams(window.location.search);

  const department = query.get(labor ? "department" : "view") || 1;

  const orderBy = query.get("orderBy") || "startDate";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    department,
    limit,
    offset,
    orderBy,
    sortOrder,
    ...(labor ? { labor } : {}),
  };

  return useQuery([DISPLAY, { ...queryProps }], getDisplayDashboard, {
    retry: false,
  });
};
