import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  setUpdatingOrder,
  getPurchaseOrderDetails,
  getPurchaseOrderItemsLength,
  getPurchaseOrderCustomerParts,
} from "../../redux/editPurchaseOrder";

import {
  useGetOrderEditData,
  usePutOrderData,
  useGetWorkOrderByOrderId,
  useGetCustomerParts,
} from "../../hooks";
import { HubHookFormWrapper, PageContent } from "../../components";
import { formOrderMapper, newItemsMapper, updatedMapper } from "./helper";

import OrderDetails from "./OrderDetails";
import OrderList from "./OrderList";
import { StyledPageStack } from "./styles";
import OrdersBar from "./OrdersBar";

const OrderPage = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetOrderEditData(id);
  const { isLoading: workLoading } = useGetWorkOrderByOrderId(id);

  const {
    mutate: putOrderData,
    isLoading: isUpdateLoading,
    isSuccess,
  } = usePutOrderData();

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    isSuccess && window.location.reload();
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const orderId = parseInt(id);
    const newItems = formData.newItems?.filter((i) => !!i?.workOrder);
    const payload = {
      id: orderId,
      customerId: data?.customer?.id,
      poNumber: formData.poNumber,
      overShipping: formData.overShipping,
      shipVia: formData.shipVia,
      shipTo: formData.shipTo,
      freight: formData.freight,
      location: formData.location,

      deleted: formData.deleted || [],
      newItems: newItemsMapper(newItems),
      updated: updatedMapper(formData.updated || []),
    };
    // console.log(payload);
    putOrderData(payload);
  };

  return (
    <PageContent isLoading={isLoading} disablePadding hideOverflow>
      <OrdersBar />
      <HubHookFormWrapper onSubmit={onSubmit} sx={{ overflow: "hidden" }}>
        <StyledPageStack>
          <OrderList />
          <OrderDetails
            isLoading={isLoading || workLoading}
            data={data}
            isUpdateLoading={isUpdateLoading}
          />
        </StyledPageStack>
      </HubHookFormWrapper>
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  details: getPurchaseOrderDetails(state),
  items: getPurchaseOrderItemsLength(state),
  parts: getPurchaseOrderCustomerParts(state),
});
const mapDispatchToProps = {
  _setUpdatingOrder: setUpdatingOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
