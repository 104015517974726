import React, { useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { onUpdateDateHelper } from "./helper";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";

const OrderLineItemNewRow = ({
  item,
  idx,
  parts = [],
  addNewRow = () => {},
  removeNewRow = () => {},
}) => {
  const [hasEdited, setHasEdited] = useState(false);

  const partItems = parts?.map((p) => ({ id: p.id, label: p.name }));
  const prefix = `newItems[${idx}].`;

  const { setValue, getValues, register } = useFormContext();

  useEffect(() => {
    if (hasEdited) addNewRow();
  }, [hasEdited]);

  useEffect(() => {
    register(prefix + "trees");
    register(prefix + "patReq");
    register(prefix + "margin");
  }, [register, prefix]);

  const onUpdatePart = (partId) => {
    const part = parts?.find((p) => p?.id === partId);
    setValue(prefix + "price", part?.lastSentPrice);
    setValue(prefix + "margin", part?.margin || 0.33);
    setHasEdited(true);
  };

  const onUpdatePrice = (price) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);

    if (!part.lastSentPrice) setValue(prefix + "margin", 0.33);
    else {
      const margin = (price * part.margin) / part.lastSentPrice;
      const final = Math.round(margin * 100) / 100;
      setValue(prefix + "margin", final);
    }
    setHasEdited(true);
  };

  const onUpdateQuantity = (quantity) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);

    const trees = Math.ceil(quantity / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    setValue(prefix + "quantity", quantity);
    setValue(prefix + "trees", trees);
    setValue(prefix + "patReq", patReq);
    setHasEdited(true);
  };

  const onUpdateDate = (value, isStart = false) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);
    const { startDate, dueDate } = onUpdateDateHelper(
      value,
      isStart,
      part?.totalDays
    );

    setValue(prefix + "startDate", startDate);
    setValue(prefix + "endDate", dueDate);
    setHasEdited(true);
  };

  const woNumber = parseInt(item?.workOrder || "0");
  const WorkOrder = `W${woNumber.toString().padStart(5, "0")}`;

  return (
    <>
      <StyledTableRow key={idx} bgDisabled main={!hasEdited}>
        <TableCell></TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "lineItem"}
            placeholder="Line"
            disableHelperText
            required={hasEdited}
            onBlur={() => setHasEdited(true)}
          />
        </TableCell>
        <TableCell sx={{ width: 250, minWidth: 250 }}>
          <HubHookFormSelect
            name={prefix + "partId"}
            onChange={(e) => onUpdatePart(e?.target?.value)}
            required={hasEdited}
            options={partItems}
            useDefault={false}
            disableHelperText
          />
        </TableCell>
        <TableCell sx={{ minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "workOrder"}
            placeholder="W00000"
            defaultValue={hasEdited && WorkOrder}
            disableHelperText
            required={hasEdited}
            onBlur={() => setHasEdited(true)}
          />
        </TableCell>
        <TableCell sx={{ minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "lotNumber"}
            placeholder="UT240000"
            disableHelperText
            required={hasEdited}
            onBlur={() => setHasEdited(true)}
          />
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "quantity"}
            placeholder="1000"
            onBlur={(e, value) => onUpdateQuantity(value)}
            disableHelperText
            isNumber
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ width: 125, minWidth: 125 }}>
          <HubHookFormInput
            name={prefix + "price"}
            placeholder="10.00"
            onBlur={(e, value) => onUpdatePrice(value)}
            disableHelperText
            isCurrency
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ minWidth: 125 }}>
          <StyledHubHookFormDatePicker
            name={prefix + "startDate"}
            placeholder="MM/DD/YYYY"
            onChange={(value) => onUpdateDate(value, true)}
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ minWidth: 125 }}>
          <StyledHubHookFormDatePicker
            name={prefix + "endDate"}
            placeholder="MM/DD/YYYY"
            onChange={(value) => onUpdateDate(value)}
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          {/* <HubHookFormInput
            name={prefix + "idx"}
            defaultValue={item?.idx}
            disableHelperText
            hidden
          /> */}
          {/* <LineItemContextMenu id={idx} removeItem={removeNewRow} /> */}
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default OrderLineItemNewRow;
