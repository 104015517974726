import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { HubButton, HubCircularProgress } from "../../components";
import { useGetDisplayDashboard } from "../../hooks";
import { StyledDisplayStack } from "./styles";

import { CreateLaborDialog } from "../../dialogs";
import { getUserRole, setIsOpenDialog } from "../../redux";

import WaxRoomTable from "./WaxRoomTable";
import StandardTable from "./StandardTable";
import InspectionTable from "./InspectionTable";
import { ROLES } from "../../constants";

const mapStateToProps = (state) => ({
  role: getUserRole(state),
});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const WaxTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ nav, role, _setIsOpenDialog = () => {} }) => {
  const [workOrder, setWorkOrder] = useState();
  const { data, isLoading, isSuccess, refetch } = useGetDisplayDashboard({
    labor: false,
  });
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [search?.toString()]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [refetch]);

  const handleSetOpen = () => {
    _setIsOpenDialog("create-labor-ticket", true);
  };

  useEffect(() => {
    const allowNav = ROLES.Generic !== role;
    if (workOrder)
      nav && allowNav ? navigate(`/works/${workOrder}`) : handleSetOpen();
  }, [workOrder]);

  const divRef = useRef(null);

  const handleFullscreen = () => {
    if (divRef.current) {
      if (divRef.current.requestFullscreen) {
        divRef.current.requestFullscreen();
      } else if (divRef.current.mozRequestFullScreen) {
        // Firefox
        divRef.current.mozRequestFullScreen();
      } else if (divRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        divRef.current.webkitRequestFullscreen();
      } else if (divRef.current.msRequestFullscreen) {
        // IE/Edge
        divRef.current.msRequestFullscreen();
      }
    }
  };

  const TableDisplay =
    data?.title === "Wax"
      ? WaxRoomTable
      : data?.title === "Inspection"
      ? InspectionTable
      : StandardTable;

  return isLoading && isSuccess ? (
    <HubCircularProgress centerScreen />
  ) : (
    <StyledDisplayStack ref={divRef} gap="10px">
      <Stack direction="row" justifyContent="space-between">
        <Typography>{data?.title || "Display Data"}</Typography>
        <HubButton
          icon="Smartphone"
          onClick={handleFullscreen}
          variant="text"
          color="neutral"
        />
      </Stack>
      <TableDisplay setWorkOrder={setWorkOrder} />
      <CreateLaborDialog workOrder={workOrder} setWorkOrder={setWorkOrder} />
    </StyledDisplayStack>
  );
});

export default WaxTable;
